import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import { injectIntl } from "gatsby-plugin-intl"
import {
  ContainerStatics,
  MainTitleStatics,
  SecondaryTitleStatics,
  TextWithMarginStatics,
} from "../components/global"
import styled from "styled-components"

const TermsAndConditions = ({ location, intl }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_terms" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_terms" })

  return (
    <Layout {...{ location }}>
      <SEO title={SEOTitle} description={SEODescription} />
      <ContainerStaticsStyled>
        <MainTitleStatics>
          {intl.formatMessage({ id: "main_title_terms" })}
        </MainTitleStatics>
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "first-block-title_terms" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "first-block-text_terms" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "second-block-title_terms" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "second-block-text_terms" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "third-block-title_terms" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "third-block-text_terms" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "fourth-block-title_terms" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "fourth-block-text_terms" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "fifth-block-title_terms" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "fifth-block-text_terms" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "sixth-block-title_terms" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "sixth-block-text_terms" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "seventh-block-title_terms" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "seventh-block-text_terms" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
      </ContainerStaticsStyled>
    </Layout>
  )
}

export default injectIntl(TermsAndConditions)

const ContainerStaticsStyled = styled(ContainerStatics)`
  margin-bottom: 100px;
`
